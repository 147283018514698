export default [
  {
    title: '版本',
    property: 'version',
  },
  {
    title: '更新人',
    property: 'update_user',
  },
  {
    title: '更新时间',
    property: 'update_time',
  },
];
