<!--  -->
<template>
  <div>
    <div class="back">
      <div class="back-top">
        <div class="back-left" @click="handleBack">
          <img src="@/assets/svg/back.svg" alt="" />
          <span>返回</span>
        </div>
        <p class="back-title">参考数据详情页</p>
      </div>
      <div class="btns" v-if="modelDetail.model_type1 === 2">
        <div class="del" @click="handleDel" v-if="modelDetail.status === 2">删除</div>
        <div class="effect" v-if="modelDetail.status === 2" @click="handleEffect(true)">
          设置生效
        </div>
        <div class="invalid effect" v-if="modelDetail.status === 1" @click="handleEffect(false)">
          设置失效
        </div>
      </div>
    </div>
    <!-- 详情信息 -->
    <div class="tag_detail_info" v-loading="loading">
      <div class="floor_top">
        <div class="floor_title">{{ modelDetail.name }}</div>
      </div>
      <div class="infomations">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="tag_description tag1">
              <p>名称：</p>
              <template v-if="!editTag.name">
                <p>{{ modelDetail.name }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.name = true"
                  v-if="modelDetail.model_type1 === 2"
                />
              </template>
              <template v-else>
                <el-input
                  class="tag_description_editInput"
                  v-model="editDetail.name"
                  placeholder="请输入"
                ></el-input>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('name')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('name')"></i>
                </div>
              </template>
            </div>
            <div class="tag_description tag1 tag_mt16">
              <p>关联元数据：</p>
              <template v-if="!editTag.dictType">
                <p>{{ modelDetail.dict_type | formatDictType }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.dictType = true"
                  v-if="modelDetail.model_type1 === 2"
                />
              </template>
              <template v-else>
                <el-select
                  v-model="editDetail.dictType"
                  placeholder="请选择"
                  class="tag_classification_editSelect"
                >
                  <el-option
                    v-for="(item, index) in relation"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('dictType')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('dictType')"></i>
                </div>
              </template>
            </div>
            <div class="tag_description tag2 tag_mt16">
              <p>说明：</p>
              <template v-if="!editTag.comment">
                <p>{{ modelDetail.comment }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.comment = true"
                  v-if="modelDetail.model_type1 === 2"
                />
              </template>
              <template v-else>
                <el-input
                  class="tag_description_editInput"
                  v-model="editDetail.comment"
                  placeholder="请输入"
                ></el-input>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('comment')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('comment')"></i>
                </div>
              </template>
            </div>
            <div class="tag_update_time tag1 tag_mt16">
              <p>更新时间：</p>
              <p>{{ modelDetail.update_time }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="tag_description tag2">
              <p>API名称：</p>
              <template v-if="!editTag.apiName">
                <p>{{ modelDetail.api_name }}</p>
                <i
                  class="el-icon-edit"
                  @click="editTag.apiName = true"
                  v-if="modelDetail.model_type1 === 2"
                />
              </template>
              <template v-else>
                <el-input
                  class="tag_description_editInput"
                  v-model="editDetail.apiName"
                  placeholder="请输入"
                ></el-input>
                <div class="tag_description_editBtn">
                  <i class="el-icon-circle-check" @click="comfirmEditTag('apiName')"></i>
                  <i class="el-icon-circle-close" @click="closeEditTag('apiName')"></i>
                </div>
              </template>
            </div>
            <div class="tag_classification tag1 tag_mt16">
              <p>层级类型：</p>
              <p>{{ modelDetail.hierarchy_type === 1 ? '单层级' : '多层级' }}</p>
            </div>
            <div class="tag_create_user tag3 tag_mt16">
              <p>创建人：</p>
              <p>{{ modelDetail.create_user }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="tag_attribute tag2">
              <p>类型：</p>
              <p>{{ modelDetail.model_type1 === 1 ? '预置' : '自定义' }}</p>
            </div>
            <div class="tag_value_type tag3 tag_mt16">
              <p>状态：</p>
              <p>{{ modelDetail.status === 1 ? '生效' : '未生效' }}</p>
            </div>
            <div class="tag_create_time tag1 tag_mt16">
              <p>创建时间：</p>
              <p>{{ modelDetail.create_time }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="detail-tabs">
      <el-tabs v-model="activeName" :before-leave="handleClick" class="tabs">
        <el-tab-pane label="标准值" name="标准值" class="tabs-first">
          <div class="btns">
            <el-button
              type="primary"
              class="add"
              v-if="modelDetail.model_type1 === 2 && modelDetail.model_type2 === 1"
              @click="handleAddValues"
            >
              新增
            </el-button>
            <el-button class="export" @click="handleExport" :disabled="!selectExport.length">
              导出
            </el-button>
          </div>

          <div v-if="filterList.length > 0" class="datalist-title-filter">
            <div
              v-for="(item, index) in filterList"
              :key="index"
              class="datalist-title-filter-item"
            >
              {{ getFlitersTitle(item.name) }}: {{ item.value }}
              <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
            </div>
            <el-button
              v-if="filterList.length"
              class="datalist-title-button"
              type="text"
              @click="deleteAllFilter"
            >
              重置
            </el-button>
          </div>
          <div class="value-table">
            <eyao-table
              v-show="!valueLoading && configList.length"
              ref="table"
              class="table"
              :table-data="tableData"
              :total-num="totalNum"
              :select-page-size="pageSize"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              @filter-change="filterChange"
              @selection-change="handleSelectionChange"
              @select-all="handleSelectAll"
            >
              <el-table-column type="selection" :reserve-selection="true" class-name="operate" />
              <el-table-column
                v-for="column in configList"
                :key="`config-${column.property}`"
                :label="column.title"
                :prop="column.property"
                :min-width="column.width"
                :sortable="column.sort"
              >
                <template slot="header" slot-scope="scope">
                  <!-- 表头 -->
                  <eyao-table-search-header
                    v-if="column.search"
                    :form-type="column.search"
                    :property="column.property"
                    :title="column.title"
                    :scope="scope"
                    @search="searchOpts"
                  ></eyao-table-search-header>
                  <!-- 无icon -->
                  <template v-else>{{ column.title }}</template>
                </template>
                <template slot-scope="scope">
                  <template v-if="column.property === 'status'">
                    {{ scope.row[column.property] === '1' ? '生效' : '未生效' }}
                  </template>
                  <template v-else>
                    {{ scope.row[column.property] !== '' ? scope.row[column.property] : '-' }}
                  </template>
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column
                label="操作"
                fixed="right"
                width="172px"
                class-name="operate"
                v-if="modelDetail.model_type1 === 2"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEdit(scope.row)" class="edit-value">
                    编辑
                  </el-button>
                  <el-button type="text" @click="handleDelValue(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <template v-slot:empty>
                <div v-if="totalNum === 0 && !valueLoading" class="no-data">
                  <img
                    src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                    alt="未查询到相关数据"
                  />
                  未查询到相关数据
                </div>
              </template>
            </eyao-table>
            <div class="listLoading" v-if="valueLoading">
              <img src="@/assets/loading.gif" alt="loading" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="修改记录" name="修改记录" class="tabs-second">
          <div class="history">
            <eyao-table
              v-show="!recordLoading"
              ref="history-table"
              class="history-table"
              :table-data="historyList"
              :total-num="historyTotalNum"
              :select-page-size="pageSize"
              @size-change="handleHistorySizeChange"
              @current-change="handleHistoryCurrentChange"
            >
              <el-table-column
                v-for="column in historyConfig"
                :key="`history-${column.title}`"
                :label="column.title"
                :prop="column.property"
              >
                <template slot-scope="scope">
                  <template v-if="column.property === 'version'">
                    {{ `V${scope.row[column.property]}` }}
                  </template>
                  <template v-else>{{ scope.row[column.property] }}</template>
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleHistoryDetail(scope.row)">
                    操作记录
                  </el-button>
                </template>
              </el-table-column>
              <template v-slot:empty>
                <div v-if="historyTotalNum === 0 && !recordLoading" class="no-data">
                  <img
                    src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                    alt="未查询到相关数据"
                  />
                  未查询到相关数据
                </div>
              </template>
            </eyao-table>
            <div class="listLoading" v-if="recordLoading">
              <img src="@/assets/loading.gif" alt="loading" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 新建模型弹窗 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      :before-close="handleClose"
      custom-class="drawer"
      size="480px"
    >
      <el-form
        ref="ruleForm"
        label-position="top"
        :model="formData"
        label-width="95px"
        class="form"
        :rules="rules"
      >
        <el-form-item label="标准值" prop="label">
          <el-input v-model="formData.label" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="值编码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="formData.enName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序">
          <el-input v-model="formData.displayOrder" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="等级顺序">
          <el-input v-model="formData.rankLevel" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入（选填）"
            v-model="formData.comment"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button class="cancel-btn" @click="handleClose">取消</el-button>
        <el-button class="save-btn" type="primary" @click="handleSave">保存</el-button>
      </div>
    </el-drawer>

    <!-- 操作记录 -->
    <el-drawer
      title="历史记录详情"
      :visible.sync="historyDrawer"
      custom-class="drawer"
      size="640px"
    >
      <div class="actiondetail">
        <!-- 中央信息显示部分 -->
        <div class="actiondetail-content">
          <div class="actiondetail-content-info">
            <div class="actiondetail-content-info-title">操作时间</div>
            <div class="actiondetail-content-info-data">{{ historyDetail.time }}</div>
          </div>
          <div class="actiondetail-content-info">
            <div class="actiondetail-content-info-title">操作用户</div>
            <div class="actiondetail-content-info-data">{{ historyDetail.user }}</div>
          </div>
          <div class="actiondetail-content-info">
            <div class="actiondetail-content-info-title">操作数据</div>
            <div class="actiondetail-content-info-link">
              {{ modelDetail.name }}
            </div>
          </div>
          <div class="actiondetail-content-info">
            <div class="actiondetail-content-info-title">操作类型</div>
            <div class="actiondetail-content-info-data">
              {{ historyDetail.type | formatOperateType }}
            </div>
          </div>
          <div
            class="actiondetail-content-table"
            v-if="historyDetail.type === 1 || historyDetail.type === 2"
          >
            <div class="actiondetail-content-info-title">操作内容</div>
            <!-- 数据表格 -->
            <el-table
              :data="detailTableData"
              stripe
              class="tab"
              :header-cell-style="headercellstyle"
            >
              <el-table-column prop="field_label_name" label="字段名称"></el-table-column>
              <el-table-column prop="before_val" label="更新前"></el-table-column>
              <el-table-column prop="after_val" label="更新后"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  GetStandardValueModels,
  UpsertStandardValueModel,
  GetStandardValues,
  ActiveStandardValueModel,
  UpsertStandardValue,
  GetStandardModelOperationLogs,
  GetStandardModelOperationLogDetails,
} from '@/api/model';
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import universalConfig from './config/universal';
import districtConfig from './config/district';
import orgTypeConfig from './config/orgType';
import departmentConfig from './config/department';
import { jsonToXlsx } from '@/utils/xlsx.js';
import historyConfig from './config/history';
export default {
  components: { eyaoTable, eyaoTableSearchHeader },
  filters: {
    formatDictType(val) {
      if (val === 't_') {
        return '通用';
      }
      if (val?.indexOf('t_hco') != -1) {
        return 'HCO';
      }
      if (val?.indexOf('t_hcp') != -1) {
        return 'HCP';
      }
      return '';
    },
    formatOperateType(val) {
      if (val === 1) {
        return '新增';
      }
      if (val === 2) {
        return '编辑';
      }
      if (val === 5) {
        return '使生效';
      }
      if (val === 6) {
        return '使失效';
      }
      if (val === 7) {
        return '标准值变更';
      }
      return '';
    },
  },
  data() {
    return {
      relation: [
        { label: '通用', value: 't_' },
        { label: 'HCO', value: 't_hco' },
        { label: 'HCP', value: 't_hcp' },
      ],
      // 编辑数据存储
      editDetail: {
        name: '',
        dictType: '',
        apiName: '',
        comment: '',
      },
      // 控制显示编辑框
      editTag: {
        name: false,
        dictType: false,
        comment: false,
        apiName: false,
      },
      modelDetail: {},
      loading: false,
      activeName: '标准值',
      tableData: [],
      configList: [],
      totalNum: 0,
      pageSize: 10,
      pageIndex: 1,
      filterList: [],
      filterObject: {},
      valueLoading: false,
      recordLoading: false,
      selectExport: [],
      drawer: false,
      formData: {
        label: '',
        code: '',
        enName: '',
        displayOrder: '',
        rankLevel: '',
        comment: '',
        id: '',
      },
      rules: {
        label: [{ required: true, message: '请输入标准值', trigger: 'blur' }],
        code: [{ required: true, message: '请输入值编码', trigger: 'blur' }],
      },
      drawerTitle: '',
      historyList: [],
      historyConfig: [],
      historyTotalNum: 0,
      historyDrawer: false,
      // historyDetailPageSize:10,
      // historyDetailPageIndex:1
      detailTableData: [],
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
      },
      historyDetail: {
        time: '',
        user: '',
        type: 0,
      },
    };
  },
  async created() {
    await this.getDetail();
    if (this.modelDetail.hierarchy_type === 2) {
      if (this.modelDetail.name === '行政区划') {
        this.configList = districtConfig;
      }
      if (this.modelDetail.name === '标准科室') {
        this.configList = departmentConfig;
      }
      if (this.modelDetail.name === '类型细分') {
        this.configList = orgTypeConfig;
      }
    } else {
      this.configList = universalConfig;
    }
    this.getValuesList();
  },
  methods: {
    // 设置生效或失效
    handleEffect(bool) {
      if (bool) {
        if (!this.tableData.length) {
          this.$message.error('此模型没有有效的标准值，请先为其创建有效的标准值再进行此操作');
          return false;
        }
      }
      const req = {
        id: this.$route.query.id,
        active: bool,
      };
      ActiveStandardValueModel(req)
        .then(res => {
          console.log(res);
          this.$message.success('设置状态成功');
          this.getDetail();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    getValuesList() {
      this.valueLoading = true;
      if (this.filterList.length) {
        const arr = this.filterList.map(item => {
          return { field_name: item.name, type: 2, keyword: item.vals };
        });
        const req = {
          model_id: this.$route.query.id,
          index: this.pageIndex,
          page_size: this.pageSize,
          filters: arr,
        };
        GetStandardValues(req)
          .then(res => {
            console.log(res);
            this.tableData = res.datas.map(item => JSON.parse(item));
            console.log(this.tableData);
            this.totalNum = res.total;
            this.valueLoading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      } else {
        const req = {
          model_id: this.$route.query.id,
          index: this.pageIndex,
          page_size: this.pageSize,
        };
        GetStandardValues(req)
          .then(res => {
            console.log(res);
            this.tableData = res.datas.map(item => JSON.parse(item));
            console.log(this.tableData);
            this.totalNum = res.total;
            this.valueLoading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },
    async getDetail() {
      this.loading = true;
      const req = {
        ids: [this.$route.query.id],
      };
      try {
        const res = await GetStandardValueModels(req);
        this.modelDetail = res.datas[0];
        this.loading = false;
      } catch (error) {
        this.$message.error(error.message);
      }
      // GetStandardValueModels(req)
      //   .then(res => {
      //     console.log(res);
      //     this.modelDetail = res.datas[0];
      //     this.loading = false;
      //   })
      //   .catch(err => {
      //     this.$message.error(err.message);
      //   });
    },
    comfirmEditTag(tab) {
      console.log(tab);
      this.editTag[tab] = false;
      const req = {
        operation: 2,
        id: this.$route.query.id,
        name: this.modelDetail.name,
        api_name: this.modelDetail.api_name,
        dict_type: this.modelDetail.dict_type,
        model_type1: this.modelDetail.model_type1,
        model_type2: this.modelDetail.model_type2,
        hierarchy_type: this.modelDetail.hierarchy_type,
        comment: this.modelDetail.comment,
      };
      if (tab === 'name') {
        req.name = this.editDetail.name;
      }
      if (tab === 'dictType') {
        req.dict_type = this.editDetail.dictType;
      }
      if (tab === 'comment') {
        req.comment = this.editDetail.comment;
      }
      if (tab === 'apiName') {
        req.api_name = this.editDetail.apiName;
      }
      UpsertStandardValueModel(req)
        .then(() => {
          this.$message.success('编辑成功');
          this.getDetail();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    closeEditTag(tab) {
      this.editTag[tab] = false;
    },
    handleBack() {
      this.g_tourl('/dashboard/reference-data-model');
    },
    handleDel() {
      if (this.modelDetail.status === 1) {
        this.$message.error('未生效的模型才可以被删除！');
        return false;
      }
      const req = {
        operation: 3,
        id: this.$route.query.id,
      };
      this.$confirm('您是否要删除当前标准值模型？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          UpsertStandardValueModel(req)
            .then(() => {
              this.$message.success('删除成功');
              this.g_tourl('/dashboard/reference-data-model');
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {});
    },
    handleClick(activeName, oldActiveName) {
      console.log(activeName, oldActiveName);
      this.activeName = activeName;
      this.pageIndex = 1;
      this.pageSize = 10;
      if (activeName === '修改记录') {
        this.historyConfig = historyConfig;
        this.getHistoryList();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getValuesList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getValuesList();
    },
    handleSelectionChange(val) {
      console.log('选中', val);
      this.selectExport = val;
    },
    handleSelectAll(val) {
      console.log(val);
    },
    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
      this.pageIndex = 1;
      this.pageSize = 10;
      this.getValuesList();
    },
    deleteFilterItem(val) {
      this.filterObject[val] = [];
      this.$refs.table.clearFilter([val]);
      this.updateFilterList();
      this.getValuesList();
    },
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach(item => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      console.log(filter);
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });

      this.updateFilterList();
    },
    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (this.filterObject[key].length !== 0) {
          list.push({
            name: key,
            value: this.filterObject[key].label,
            vals: this.filterObject[key].value,
          });
        }
      });
      this.filterList = list;
      console.log(this.filterList);
    },
    deleteAllFilter() {
      this.filterList = [];
      this.filterObject = {};
      this.pageIndex = 1;
      this.pageSize = 10;
      this.getValuesList();
    },
    handleEdit(val) {
      console.log(val);
      this.drawerTitle = '编辑标准值';
      this.drawer = true;
      this.formData = {
        label: val.label,
        code: val.code,
        enName: val.label_en,
        displayOrder: val.display_order,
        rankLevel: val.rank_level,
        comment: val.comment,
        id: val.id,
      };
    },
    handleDelValue(val) {
      console.log(val);
      this.$confirm('确认删除当前标准值？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const req = {
            operation: 3,
            id: val.id,
          };
          UpsertStandardValue(req)
            .then(req)
            .then(() => {
              this.$message.success('删除成功');
              this.getValuesList();
              this.selectExport = [];
              console.log(this.selectExport);
              this.$refs.table.clearSelection();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {});
    },
    // 导出
    handleExport() {
      if (this.selectExport.length > 5000) {
        this.$message.error('超过5000条上线，请分批下载');
        return false;
      }
      const exportList = [];
      this.selectExport.forEach(item => {
        const obj = {};
        this.configList.forEach(({ title, property }) => {
          if (title === '状态') {
            obj[title] = item[property] === '1' ? '生效' : '未生效';
          } else {
            obj[title] = item[property];
          }
        });

        exportList.push(obj);
      });
      console.log('格式化', exportList);
      const loading = this.$loading({
        lock: true,
        text: '正在导出,请稍后...',
        spinner: 'el-exprot-icon',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      jsonToXlsx([{ sheet: exportList }], `${this.modelDetail.name}参考数据详情`);
      loading.close();
    },
    handleAddValues() {
      this.drawer = true;
      this.drawerTitle = '新建标准值';
    },

    handleClose() {
      this.drawer = false;
      this.formData = {
        label: '',
        code: '',
        enName: '',
        displayOrder: '',
        rankLevel: '',
        comment: '',
        id: '',
      };
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const req = {
            operation: this.drawerTitle === '编辑标准值' ? 2 : 1,
            id: this.formData.id,
            model_id: this.$route.query.id,
            label: this.formData.label,
            label_en: this.formData.enName,
            code: this.formData.code,
            display_order: this.formData.displayOrder,
            rank_level: this.formData.rankLevel,
            hierarchy_name: '',
            hierarchy_level: 1,
            comment: this.formData.comment,
          };
          UpsertStandardValue(req)
            .then(res => {
              console.log(res);
              this.drawer = false;
              this.$message.success(`${this.drawerTitle}成功`);
              this.formData = {
                label: '',
                code: '',
                enName: '',
                displayOrder: '',
                rankLevel: '',
                comment: '',
                id: '',
              };
              this.getValuesList();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        } else {
          return false;
        }
      });
    },
    getHistoryList() {
      this.recordLoading = true;
      const req = {
        model_id: this.$route.query.id,
        index: this.pageIndex,
        page_size: this.pageSize,
      };
      GetStandardModelOperationLogs(req)
        .then(res => {
          console.log('历史记录', res);
          this.historyList = res.datas;
          this.historyTotalNum = res.total;
          this.recordLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleHistorySizeChange(val) {
      this.pageSize = val;
      this.getHistoryList();
    },
    handleHistoryCurrentChange(val) {
      this.pageIndex = val;
      this.getHistoryList();
    },
    handleHistoryDetail(val) {
      this.historyDetail = {
        time: val.update_time,
        user: val.update_user,
        type: val.operation,
      };
      console.log(this.historyDetail);
      this.historyDrawer = true;
      const req = {
        log_id: val.log_id,
      };
      GetStandardModelOperationLogDetails(req)
        .then(res => {
          console.log(res);
          this.detailTableData = res.datas;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
